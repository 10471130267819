import question from './../apis/question'

const QuestionReports = {
  state: {
    questionReports: [],
    questionDetail: null,
    totalRecord: 0
  },
  mutations: {
    setQuestionReports(state, payload) {
      state.questionReports = payload
    },
    setTotalRecord(state, payload) {
      state.totalRecord = payload
    },
    setQuestionDetail(state, payload){
      state.questionDetail = payload
    }
  },
  actions: {
    async getAllQuestionReports({ commit }, { skip, take, search }) {
      const response = await question.getAllQuestionReports({
        search: search,
        skip: skip,
        take: take,
      })
      if (response.isSuccess) {
        // let data = []
        // for (const elm of response.data) {
        //   data.push({
        //     questionId: elm.questionId,
        //     title: elm.title,
        //     questionDescription: elm.questionDescription,
        //     category: elm.categoryName,
        //     name: elm.name,
        //     isDisable: elm.isDisabled,
        //   })
        // }
        commit('setQuestionReports', response.data)
        commit('setTotalRecord', response.total)
      }
    },
  
  },
  getters: {},
}

export default QuestionReports
