import Vuex from 'vuex'
import categoryModule from './Category'
import userModule from './User'
import productModule from './Product'
import reviewModule from './Review'
import commentModule from './Comment'
import questionModule from './Question'
import answerModule from './Answer'
import sharedModule from './Shared'
import questionCommentModule from './QuestionComment'
import productReportsModule from './ProductReports'
import reviewReportsModule from './ReviewReports'
import commentReporstModule from './CommentReports'
import questionReportsModule from './QuestionReports'
import answerReportsModule from './AnswerReports'
import questionCommentReportsModule from './QuestionCommentReports'

const store = new Vuex.Store({
  modules: {
    category: categoryModule,
    user: userModule,
    product: productModule,
    productReport: productReportsModule,
    review: reviewModule,
    reviewReports: reviewReportsModule,
    comment: commentModule,
    commentReports: commentReporstModule,
    question: questionModule,
    questionReports  :questionReportsModule,
    answer: answerModule,
    answerReports : answerReportsModule,
    shared: sharedModule,
    questionComment : questionCommentModule,
    questionCommentReports: questionCommentReportsModule
  },
})
export default store
