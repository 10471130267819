import comment from './../apis/comment'

const CommentReports = {
  state: {
    commentReports: [],
    totalRecord: 0,
    
  },
  mutations: {
    setCommentReports(state, payload) {
      state.commentReports = payload
    },
    setTotalRecord(state, payload) {
      state.totalRecord = payload
    },
  },
  actions: {
    async getAllReviewCommentReports({ commit }, { skip, take, search }) {
      const response = await comment.getAllReviewCommentsReports({
        search: search,
        skip: skip,
        take: take,
      })
      if (response.isSuccess) {
        commit('setCommentReports', response.data)
        commit('setTotalRecord', response.total)
      }
    },
  },
  getters: {},
}

export default CommentReports
