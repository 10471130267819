import questionComment from '../apis/questionComment'

const QuestionCommentReports = {
  state: {
    questionCommentReports: [],
    totalRecord: 0,
    commentDetail: null
  },
  mutations: {
    setComment(state, payload) {
      state.questionCommentReports = payload
    },
    setTotalRecord(state, payload) {
      state.totalRecord = payload
    },
    setCommentDetail(state, payload) {
      state.commentDetail = payload
    }
  },
  actions: {
    async getAllQuestionCommentReports({ commit }, { skip, take, search }) {
      const response = await questionComment.getAllQuestionCommentReports({
        search: search,
        skip: skip,
        take: take,
      })
      if (response.isSuccess) {

        commit('setComment', response.data)
        commit('setTotalRecord', response.total)
      }
    },
  
  },
  getters: {},
}

export default QuestionCommentReports
