import review from './../apis/review'

const ReviewReport = {
    state: {
        reviewReports: [],
        totalRecord: 0,
        reviewDetail: null
    },
    mutations: {
        setReview(state, payload) {
            state.reviewReports = payload
        },
        setTotal(state, payload) {
            state.totalRecord = payload
        },

    },
    actions: {
        async getAllReviewReports({ commit }, { skip, take, search }) {
            const response = await review.getAllReviewReports({
                search: search,
                skip: skip,
                take: take

            })
            if (response.isSuccess) {
                let data = []
                data = response.data


                commit('setReview', data)
                commit('setTotal', response.total);
            }
        },
       
    },
    getters: {},
}

export default ReviewReport
