import answer from './../apis/answer'

const AnswerReports = {
  state: {
    answerReports: [],
    totalRecord: 0,
  },
  mutations: {
    setAnswerReports(state, payload) {
      state.answerReports = payload
    },
    setTotalRecord(state, payload) {
      state.totalRecord = payload
    },

  },
  actions: {
    async getAllAnswerReports({ commit }, { skip, take, search }) {
      const response = await answer.GetAllAnswerReports({
        search: search,
        skip: skip,
        take: take,
      })
      if (response.isSuccess) {
        console.log(response);
        commit('setAnswerReports', response.data)
        
        commit('setTotalRecord', response.total)
      }
    },
   
  },
  getters: {},
}

export default AnswerReports
